<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="companyprofile" />
    <v-container fluid>
      <v-card class="pa-8">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="packagename" class="font-style">{{
                $t("packagename")
              }}</label>
              <v-text-field
                disabled
                class="text-style"
                outlined
                dense
                v-model="company.packagename"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="expirydate" class="font-style">{{
                $t("expirydate")
              }}</label>
              <v-text-field
                disabled
                class="text-style"
                v-model="company.expirydate"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="companyname" class="font-style">
                {{ $t("companyname") }}
                <span style="color: red">*</span>
              </label>
              <v-text-field
                v-model="company.name"
                dense
                class="text-style"
                :disabled="disable"
                outlined
                :rules="nameRules"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="companycode" class="font-style">{{
                $t("companycode")
              }}</label>
              <v-text-field
                disabled
                outlined
                dense
                class="text-style"
                v-model="company.code"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="address" class="font-style">
                {{ $t("address") }}
                <span style="color: red">*</span>
              </label>
              <v-text-field
                :disabled="disable"
                outlined
                :rules="addressRules"
                dense
                class="text-style"
                v-model="company.address"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="country" class="font-style">
                {{ $t("country") }}
                <span style="color: red">*</span>
              </label>
              <v-text-field
                :disabled="disable"
                class="text-style"
                v-model="company.country"
                outlined
                :rules="countryRules"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="province" class="font-style">
                {{ $t("province") }}
                <span style="color: red">*</span>
              </label>
              <v-text-field
                :disabled="disable"
                class="text-style"
                outlined
                dense
                :rules="provinceRules"
                v-model="company.province"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="contactemail" class="font-style">
                {{ $t("contactemail") }}
                <span style="color: red">*</span>
              </label>
              <v-text-field
                :disabled="disable"
                outlined
                :rules="emailRules"
                dense
                class="text-style"
                v-model="company.contactemail"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="ph" class="font-style">
                {{ $t("ph") }}
                <span style="color: red">*</span>
              </label>

              <v-text-field
                :disabled="disable"
                outlined
                :rules="phoneRules"
                dense
                v-mask="'###########'"
                class="text-style"
                v-model="company.phone"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <label for="contactperson" class="font-style">
                {{ $t("contactperson") }}
                <span style="color: red">*</span>
              </label>
              <v-text-field
                :disabled="disable"
                class="text-style"
                dense
                :rules="contactnameRules"
                outlined
                v-model="company.contactperson"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row></v-row>
        </v-form>

        <v-row v-if="disable">
          <v-col class="d-flex justify-end">
            <v-btn
              color="secondary"
              @click="editProfile()"
              style="font-size: 18px"
              class="mt-4 white--text"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
              {{ $t("edit") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col class="d-flex justify-end">
            <v-btn
              text
              outlined
              @click="Cancel()"
              style="font-size: 18px"
              width="100"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              width="100"
              color="secondary"
              @click="Update()"
              style="font-size: 18px"
              class="ml-3 white--text"
              >{{ $t("save") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import * as moment from "moment/moment";

export default {
  directives: { mask },
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    breadcrumbs: [
      {
        //image: "house.png"
        sidebar_tilte: "home",
      },
      {
        text: "companyprofile", //assignmentcourse
      },
    ],
    disable: true,
    valid: false,
    company: {
      name: "",
      code: "",
      address: "",
      province: "",
      phone: "",
      country: "",
      contactemail: "",
      contactperson: "",
      packagename: "",
      expirydate: "",
    },
    companyData: null,

    isEdit: false,
  }),
  computed: {
    phoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) =>
          (v && v.length >= 10) ||
          "Phone Number must be greather than 10 characters",
        (v) =>
          (v && v.length <= 11) ||
          "Phone Number must be less than 11 characters",
      ];
    },
    nameRules() {
      return [(v) => !!v || "Company name is required"];
    },
    addressRules() {
      return [(v) => !!v || "Address is required"];
    },
    provinceRules() {
      return [(v) => !!v || "Province is required"];
    },
    countryRules() {
      return [(v) => !!v || "Country is required"];
    },
    emailRules() {
      return [
        (v) => !!v || "Contact email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    contactnameRules() {
      return [(v) => !!v || "Contact name is required"];
    },
  },
  methods: {
    editProfile() {
      let checkPermission = this.check_Permissions("companyprofile");
      if (!checkPermission) {
        this.permissionDialog = true;
        this.permissionMessage =
          "You don't have permission to edit Company Profile.";
      } else {
        this.disable = false;
      }
    },
    async CompanyData() {
      let that = this;
      await axios
        .post(
          `${that.web_url}Company/GetCompanyByIDForCustomerWeb?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.companyData = response.data.data;
            that.company.name = response.data.data.companyName;
            that.company.code = response.data.data.abb;
            that.company.address = response.data.data.address;
            that.company.province = response.data.data.city;
            that.company.phone = response.data.data.telNo;
            that.company.country = response.data.data.country;
            that.company.contactemail = response.data.data.email;
            that.company.contactperson = response.data.data.contactName;
            that.company.packagename = response.data.data.packageName;
            that.company.expirydate = response.data.data.expiryDate
              ? moment(response.data.data.expiryDate)
                  .local()
                  .format("DD/MM/YYYY")
              : "";
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    Edit() {
      let that = this;
      that.isEdit = true;
      that.disable = false;
    },
    async Update() {
      let that = this;
      if (this.$refs.form.validate()) {
        let request = {
          companyId: localStorage.getItem("companyID"),
          companyName: that.company.name,
          abb: that.company.code,
          telNo: that.company.phone,
          email: that.company.contactemail,
          country: that.company.country,
          city: that.company.province,
          address: that.company.address,
          contactName: that.company.contactperson,
          updateBy: localStorage.getItem("userName"),
        };
        await that.$axios
          .post(`${that.web_url}Company/UpdateCompanyFromCustomerWeb`, request)
          .then(function (response) {
            if (response.data.status == 0) {
              that.disable = true;
            }
          })
          .catch(function (err) {
            throw err;
          });
      }
    },
    Cancel() {
      this.company.name = this.companyData.companyName;
      this.company.code = this.companyData.abb;
      this.company.address = this.companyData.address;
      this.company.province = this.companyData.city;
      this.company.phone = this.companyData.telNo;
      this.company.country = this.companyData.country;
      this.company.contactemail = this.companyData.email;
      this.company.contactperson = this.companyData.contactName;
      this.disable = true;
    },
  },
  mounted() {
    this.CompanyData();
  },
};
</script>

<style scoped>
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
.font-style {
  color: #4d4f5c;
  font-size: 18px;
}
.text-style {
  margin-top: 12px;
  font-size: 18px;
}
::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #707070;
  background: white;
}

::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #bbbbbb33;
  background: #bbbbbb33;
}
</style>

<style>
.v-card {
  border-radius: 16px !important;
}
</style>
